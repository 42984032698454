/* tslint:disable */
/* eslint-disable */
/* @relayHash e1116183a192ba37c45dbee1786f45c2 */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ConversationModalRepliesQueryVariables = {
    commentID: string;
};
export type ConversationModalRepliesQueryResponse = {
    readonly settings: {
        readonly " $fragmentRefs": FragmentRefs<"ConversationModalCommentContainer_settings">;
    };
    readonly comment: {
        readonly replies: {
            readonly edges: ReadonlyArray<{
                readonly node: {
                    readonly id: string;
                    readonly " $fragmentRefs": FragmentRefs<"ConversationModalCommentContainer_comment">;
                };
            }>;
        };
    } | null;
};
export type ConversationModalRepliesQuery = {
    readonly response: ConversationModalRepliesQueryResponse;
    readonly variables: ConversationModalRepliesQueryVariables;
};



/*
query ConversationModalRepliesQuery(
  $commentID: ID!
) {
  settings {
    ...ConversationModalCommentContainer_settings
    id
  }
  comment(id: $commentID) {
    replies {
      edges {
        node {
          id
          ...ConversationModalCommentContainer_comment
        }
      }
    }
    id
  }
}

fragment AutomatedActionsContainer_settings on Settings {
  integrations {
    perspective {
      threshold
    }
    external {
      phases {
        name
        id
      }
    }
  }
}

fragment ConversationModalCommentContainer_comment on Comment {
  id
  body
  createdAt
  author {
    username
    id
  }
  replyCount
  parent {
    author {
      username
      id
    }
    id
  }
  ...MediaContainer_comment
}

fragment ConversationModalCommentContainer_settings on Settings {
  multisite
  featureFlags
  ...MarkersContainer_settings
}

fragment LinkDetailsContainer_settings on Settings {
  organization {
    url
  }
}

fragment MarkersContainer_settings on Settings {
  ...ModerateCardDetailsContainer_settings
}

fragment MediaContainer_comment on Comment {
  id
  site {
    id
  }
  revision {
    media {
      __typename
      ... on GiphyMedia {
        url
        title
        width
        height
        still
        video
      }
      ... on TwitterMedia {
        url
      }
      ... on YouTubeMedia {
        url
        still
        title
      }
      ... on ExternalMedia {
        url
      }
    }
    id
  }
}

fragment ModerateCardDetailsContainer_settings on Settings {
  ...LinkDetailsContainer_settings
  ...AutomatedActionsContainer_settings
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "commentID"
        } as any)
    ], v1 = [
        ({
            "kind": "Variable",
            "name": "id",
            "variableName": "commentID"
        } as any)
    ], v2 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any), v3 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "url",
        "storageKey": null
    } as any), v4 = [
        (v3 /*: any*/)
    ], v5 = ({
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "author",
        "plural": false,
        "selections": [
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "username",
                "storageKey": null
            },
            (v2 /*: any*/)
        ],
        "storageKey": null
    } as any), v6 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "title",
        "storageKey": null
    } as any), v7 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "still",
        "storageKey": null
    } as any);
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "ConversationModalRepliesQuery",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Settings",
                    "kind": "LinkedField",
                    "name": "settings",
                    "plural": false,
                    "selections": [
                        {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "ConversationModalCommentContainer_settings"
                        }
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "Comment",
                    "kind": "LinkedField",
                    "name": "comment",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "CommentsConnection",
                            "kind": "LinkedField",
                            "name": "replies",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "CommentEdge",
                                    "kind": "LinkedField",
                                    "name": "edges",
                                    "plural": true,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "Comment",
                                            "kind": "LinkedField",
                                            "name": "node",
                                            "plural": false,
                                            "selections": [
                                                (v2 /*: any*/),
                                                {
                                                    "args": null,
                                                    "kind": "FragmentSpread",
                                                    "name": "ConversationModalCommentContainer_comment"
                                                }
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "ConversationModalRepliesQuery",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Settings",
                    "kind": "LinkedField",
                    "name": "settings",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "multisite",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "featureFlags",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Organization",
                            "kind": "LinkedField",
                            "name": "organization",
                            "plural": false,
                            "selections": (v4 /*: any*/),
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "ExternalIntegrations",
                            "kind": "LinkedField",
                            "name": "integrations",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "PerspectiveExternalIntegration",
                                    "kind": "LinkedField",
                                    "name": "perspective",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "threshold",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "CustomExternalIntegration",
                                    "kind": "LinkedField",
                                    "name": "external",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "ExternalModerationPhase",
                                            "kind": "LinkedField",
                                            "name": "phases",
                                            "plural": true,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "name",
                                                    "storageKey": null
                                                },
                                                (v2 /*: any*/)
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        (v2 /*: any*/)
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "Comment",
                    "kind": "LinkedField",
                    "name": "comment",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "CommentsConnection",
                            "kind": "LinkedField",
                            "name": "replies",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "CommentEdge",
                                    "kind": "LinkedField",
                                    "name": "edges",
                                    "plural": true,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "Comment",
                                            "kind": "LinkedField",
                                            "name": "node",
                                            "plural": false,
                                            "selections": [
                                                (v2 /*: any*/),
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "body",
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "createdAt",
                                                    "storageKey": null
                                                },
                                                (v5 /*: any*/),
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "replyCount",
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "Comment",
                                                    "kind": "LinkedField",
                                                    "name": "parent",
                                                    "plural": false,
                                                    "selections": [
                                                        (v5 /*: any*/),
                                                        (v2 /*: any*/)
                                                    ],
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "Site",
                                                    "kind": "LinkedField",
                                                    "name": "site",
                                                    "plural": false,
                                                    "selections": [
                                                        (v2 /*: any*/)
                                                    ],
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "CommentRevision",
                                                    "kind": "LinkedField",
                                                    "name": "revision",
                                                    "plural": false,
                                                    "selections": [
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "concreteType": null,
                                                            "kind": "LinkedField",
                                                            "name": "media",
                                                            "plural": false,
                                                            "selections": [
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "kind": "ScalarField",
                                                                    "name": "__typename",
                                                                    "storageKey": null
                                                                },
                                                                {
                                                                    "kind": "InlineFragment",
                                                                    "selections": [
                                                                        (v3 /*: any*/),
                                                                        (v6 /*: any*/),
                                                                        {
                                                                            "alias": null,
                                                                            "args": null,
                                                                            "kind": "ScalarField",
                                                                            "name": "width",
                                                                            "storageKey": null
                                                                        },
                                                                        {
                                                                            "alias": null,
                                                                            "args": null,
                                                                            "kind": "ScalarField",
                                                                            "name": "height",
                                                                            "storageKey": null
                                                                        },
                                                                        (v7 /*: any*/),
                                                                        {
                                                                            "alias": null,
                                                                            "args": null,
                                                                            "kind": "ScalarField",
                                                                            "name": "video",
                                                                            "storageKey": null
                                                                        }
                                                                    ],
                                                                    "type": "GiphyMedia",
                                                                    "abstractKey": null
                                                                },
                                                                {
                                                                    "kind": "InlineFragment",
                                                                    "selections": (v4 /*: any*/),
                                                                    "type": "TwitterMedia",
                                                                    "abstractKey": null
                                                                },
                                                                {
                                                                    "kind": "InlineFragment",
                                                                    "selections": [
                                                                        (v3 /*: any*/),
                                                                        (v7 /*: any*/),
                                                                        (v6 /*: any*/)
                                                                    ],
                                                                    "type": "YouTubeMedia",
                                                                    "abstractKey": null
                                                                },
                                                                {
                                                                    "kind": "InlineFragment",
                                                                    "selections": (v4 /*: any*/),
                                                                    "type": "ExternalMedia",
                                                                    "abstractKey": null
                                                                }
                                                            ],
                                                            "storageKey": null
                                                        },
                                                        (v2 /*: any*/)
                                                    ],
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        (v2 /*: any*/)
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "id": "e1116183a192ba37c45dbee1786f45c2",
            "metadata": {},
            "name": "ConversationModalRepliesQuery",
            "operationKind": "query",
            "text": null
        }
    } as any;
})();
(node as any).hash = 'c98deda035b821eaab5783b9efa909a6';
export default node;
