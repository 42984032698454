/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ConversationModalRepliesContainer_settings = {
    readonly " $fragmentRefs": FragmentRefs<"ConversationModalCommentContainer_settings">;
    readonly " $refType": "ConversationModalRepliesContainer_settings";
};
export type ConversationModalRepliesContainer_settings$data = ConversationModalRepliesContainer_settings;
export type ConversationModalRepliesContainer_settings$key = {
    readonly " $data"?: ConversationModalRepliesContainer_settings$data;
    readonly " $fragmentRefs": FragmentRefs<"ConversationModalRepliesContainer_settings">;
};



const node: ReaderFragment = ({
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ConversationModalRepliesContainer_settings",
    "selections": [
        {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ConversationModalCommentContainer_settings"
        }
    ],
    "type": "Settings",
    "abstractKey": null
} as any);
(node as any).hash = 'e8bc11433bf96be71bf642c983bc9769';
export default node;
