/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type CustomCSSConfig_formValues = {
    readonly customCSSURL: string | null;
    readonly " $refType": "CustomCSSConfig_formValues";
};
export type CustomCSSConfig_formValues$data = CustomCSSConfig_formValues;
export type CustomCSSConfig_formValues$key = {
    readonly " $data"?: CustomCSSConfig_formValues$data;
    readonly " $fragmentRefs": FragmentRefs<"CustomCSSConfig_formValues">;
};



const node: ReaderFragment = ({
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "CustomCSSConfig_formValues",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "customCSSURL",
            "storageKey": null
        }
    ],
    "type": "Settings",
    "abstractKey": null
} as any);
(node as any).hash = 'd118185ac6db458cfa588548dc4e3f34';
export default node;
