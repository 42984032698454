/* tslint:disable */
/* eslint-disable */
/* @relayHash 23ee4236deee6ca94961e2ceb83296fa */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type MainRouteQueryVariables = {};
export type MainRouteQueryResponse = {
    readonly viewer: {
        readonly " $fragmentRefs": FragmentRefs<"ErrorReporterSetUserContainer_viewer" | "UserMenuContainer_viewer" | "NavigationContainer_viewer">;
    } | null;
};
export type MainRouteQuery = {
    readonly response: MainRouteQueryResponse;
    readonly variables: MainRouteQueryVariables;
};



/*
query MainRouteQuery {
  viewer {
    ...ErrorReporterSetUserContainer_viewer
    ...UserMenuContainer_viewer
    ...NavigationContainer_viewer
    id
  }
}

fragment ErrorReporterSetUserContainer_viewer on User {
  id
  username
  role
}

fragment NavigationContainer_viewer on User {
  role
}

fragment UserMenuContainer_viewer on User {
  username
}
*/

const node: ConcreteRequest = ({
    "fragment": {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "metadata": null,
        "name": "MainRouteQuery",
        "selections": [
            {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "viewer",
                "plural": false,
                "selections": [
                    {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "ErrorReporterSetUserContainer_viewer"
                    },
                    {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "UserMenuContainer_viewer"
                    },
                    {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "NavigationContainer_viewer"
                    }
                ],
                "storageKey": null
            }
        ],
        "type": "Query",
        "abstractKey": null
    },
    "kind": "Request",
    "operation": {
        "argumentDefinitions": [],
        "kind": "Operation",
        "name": "MainRouteQuery",
        "selections": [
            {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "viewer",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "id",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "username",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "role",
                        "storageKey": null
                    }
                ],
                "storageKey": null
            }
        ]
    },
    "params": {
        "id": "23ee4236deee6ca94961e2ceb83296fa",
        "metadata": {},
        "name": "MainRouteQuery",
        "operationKind": "query",
        "text": null
    }
} as any);
(node as any).hash = '14f8f97d8eec90fb3125e240a942d3eb';
export default node;
