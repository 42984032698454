/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type StoryTableContainer_query = {
    readonly settings: {
        readonly multisite: boolean;
    };
    readonly stories: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly " $fragmentRefs": FragmentRefs<"StoryRowContainer_story">;
            };
        }>;
    };
    readonly " $refType": "StoryTableContainer_query";
};
export type StoryTableContainer_query$data = StoryTableContainer_query;
export type StoryTableContainer_query$key = {
    readonly " $data"?: StoryTableContainer_query$data;
    readonly " $fragmentRefs": FragmentRefs<"StoryTableContainer_query">;
};



const node: ReaderFragment = ({
    "argumentDefinitions": [
        {
            "defaultValue": 10,
            "kind": "LocalArgument",
            "name": "count"
        },
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "cursor"
        },
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "searchFilter"
        },
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "siteID"
        },
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "statusFilter"
        }
    ],
    "kind": "Fragment",
    "metadata": {
        "connection": [
            {
                "count": "count",
                "cursor": "cursor",
                "direction": "forward",
                "path": [
                    "stories"
                ]
            }
        ]
    },
    "name": "StoryTableContainer_query",
    "selections": [
        {
            "alias": null,
            "args": null,
            "concreteType": "Settings",
            "kind": "LinkedField",
            "name": "settings",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "multisite",
                    "storageKey": null
                }
            ],
            "storageKey": null
        },
        {
            "alias": "stories",
            "args": [
                {
                    "kind": "Variable",
                    "name": "query",
                    "variableName": "searchFilter"
                },
                {
                    "kind": "Variable",
                    "name": "siteID",
                    "variableName": "siteID"
                },
                {
                    "kind": "Variable",
                    "name": "status",
                    "variableName": "statusFilter"
                }
            ],
            "concreteType": "StoriesConnection",
            "kind": "LinkedField",
            "name": "__StoryTable_stories_connection",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "StoryEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Story",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "id",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "__typename",
                                    "storageKey": null
                                },
                                {
                                    "args": null,
                                    "kind": "FragmentSpread",
                                    "name": "StoryRowContainer_story"
                                }
                            ],
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "cursor",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "endCursor",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "hasNextPage",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ],
    "type": "Query",
    "abstractKey": null
} as any);
(node as any).hash = '4bf4ac0413a9b2ec5fecd85e71e49442';
export default node;
