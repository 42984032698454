/* tslint:disable */
/* eslint-disable */
/* @relayHash 25d07fdc2a9122f63b6532d347f78dd4 */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type COMMENT_SORT = "CREATED_AT_ASC" | "CREATED_AT_DESC" | "REACTION_DESC" | "REPLIES_DESC" | "%future added value";
export type SectionFilter = {
    name?: string | null;
};
export type ForReviewQueueRoutePaginationQueryVariables = {
    storyID?: string | null;
    siteID?: string | null;
    section?: SectionFilter | null;
    cursor?: unknown | null;
    count: number;
    orderBy?: COMMENT_SORT | null;
};
export type ForReviewQueueRoutePaginationQueryResponse = {
    readonly " $fragmentRefs": FragmentRefs<"ForReviewQueueRoute_query">;
};
export type ForReviewQueueRoutePaginationQuery = {
    readonly response: ForReviewQueueRoutePaginationQueryResponse;
    readonly variables: ForReviewQueueRoutePaginationQueryVariables;
};



/*
query ForReviewQueueRoutePaginationQuery(
  $storyID: ID
  $siteID: ID
  $section: SectionFilter
  $cursor: Cursor
  $count: Int!
  $orderBy: COMMENT_SORT
) {
  ...ForReviewQueueRoute_query_2pwXUu
}

fragment ForReviewQueueRoute_query_2pwXUu on Query {
  flags(first: $count, after: $cursor, orderBy: $orderBy, storyID: $storyID, siteID: $siteID, section: $section) {
    edges {
      node {
        id
        ...ForReviewQueueRowContainer_flag
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
  viewer {
    id
  }
  settings {
    forReviewQueue
    id
  }
}

fragment ForReviewQueueRowContainer_flag on Flag {
  id
  createdAt
  flagger {
    username
    id
  }
  reason
  additionalDetails
  reviewed
  revision {
    body
    id
  }
  comment {
    id
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = ({
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "count"
    } as any), v1 = ({
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "cursor"
    } as any), v2 = ({
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "orderBy"
    } as any), v3 = ({
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "section"
    } as any), v4 = ({
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "siteID"
    } as any), v5 = ({
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "storyID"
    } as any), v6 = ({
        "kind": "Variable",
        "name": "orderBy",
        "variableName": "orderBy"
    } as any), v7 = ({
        "kind": "Variable",
        "name": "section",
        "variableName": "section"
    } as any), v8 = ({
        "kind": "Variable",
        "name": "siteID",
        "variableName": "siteID"
    } as any), v9 = ({
        "kind": "Variable",
        "name": "storyID",
        "variableName": "storyID"
    } as any), v10 = [
        ({
            "kind": "Variable",
            "name": "after",
            "variableName": "cursor"
        } as any),
        ({
            "kind": "Variable",
            "name": "first",
            "variableName": "count"
        } as any),
        (v6 /*: any*/),
        (v7 /*: any*/),
        (v8 /*: any*/),
        (v9 /*: any*/)
    ], v11 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any), v12 = [
        (v11 /*: any*/)
    ];
    return {
        "fragment": {
            "argumentDefinitions": [
                (v0 /*: any*/),
                (v1 /*: any*/),
                (v2 /*: any*/),
                (v3 /*: any*/),
                (v4 /*: any*/),
                (v5 /*: any*/)
            ],
            "kind": "Fragment",
            "metadata": null,
            "name": "ForReviewQueueRoutePaginationQuery",
            "selections": [
                {
                    "args": [
                        {
                            "kind": "Variable",
                            "name": "count",
                            "variableName": "count"
                        },
                        {
                            "kind": "Variable",
                            "name": "cursor",
                            "variableName": "cursor"
                        },
                        (v6 /*: any*/),
                        (v7 /*: any*/),
                        (v8 /*: any*/),
                        (v9 /*: any*/)
                    ],
                    "kind": "FragmentSpread",
                    "name": "ForReviewQueueRoute_query"
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [
                (v5 /*: any*/),
                (v4 /*: any*/),
                (v3 /*: any*/),
                (v1 /*: any*/),
                (v0 /*: any*/),
                (v2 /*: any*/)
            ],
            "kind": "Operation",
            "name": "ForReviewQueueRoutePaginationQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v10 /*: any*/),
                    "concreteType": "FlagsConnection",
                    "kind": "LinkedField",
                    "name": "flags",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "FlagEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Flag",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                        (v11 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "createdAt",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "User",
                                            "kind": "LinkedField",
                                            "name": "flagger",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "username",
                                                    "storageKey": null
                                                },
                                                (v11 /*: any*/)
                                            ],
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "reason",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "additionalDetails",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "reviewed",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "CommentRevision",
                                            "kind": "LinkedField",
                                            "name": "revision",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "body",
                                                    "storageKey": null
                                                },
                                                (v11 /*: any*/)
                                            ],
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "Comment",
                                            "kind": "LinkedField",
                                            "name": "comment",
                                            "plural": false,
                                            "selections": (v12 /*: any*/),
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "__typename",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "cursor",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "PageInfo",
                            "kind": "LinkedField",
                            "name": "pageInfo",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "endCursor",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "hasNextPage",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": (v10 /*: any*/),
                    "filters": [
                        "orderBy",
                        "storyID",
                        "siteID",
                        "section"
                    ],
                    "handle": "connection",
                    "key": "ForReviewQueue_flags",
                    "kind": "LinkedHandle",
                    "name": "flags"
                },
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "viewer",
                    "plural": false,
                    "selections": (v12 /*: any*/),
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Settings",
                    "kind": "LinkedField",
                    "name": "settings",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "forReviewQueue",
                            "storageKey": null
                        },
                        (v11 /*: any*/)
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "id": "25d07fdc2a9122f63b6532d347f78dd4",
            "metadata": {},
            "name": "ForReviewQueueRoutePaginationQuery",
            "operationKind": "query",
            "text": null
        }
    } as any;
})();
(node as any).hash = '004da3264857d41665ba69c5dcbce853';
export default node;
