/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type USER_ROLE = "ADMIN" | "COMMENTER" | "MODERATOR" | "STAFF" | "%future added value";
export type UserStatusChangeContainer_viewer = {
    readonly role: USER_ROLE;
    readonly moderationScopes: {
        readonly scoped: boolean;
        readonly sites: ReadonlyArray<{
            readonly id: string;
            readonly name: string;
        }> | null;
    } | null;
    readonly " $refType": "UserStatusChangeContainer_viewer";
};
export type UserStatusChangeContainer_viewer$data = UserStatusChangeContainer_viewer;
export type UserStatusChangeContainer_viewer$key = {
    readonly " $data"?: UserStatusChangeContainer_viewer$data;
    readonly " $fragmentRefs": FragmentRefs<"UserStatusChangeContainer_viewer">;
};



const node: ReaderFragment = ({
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "UserStatusChangeContainer_viewer",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "role",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "concreteType": "UserModerationScopes",
            "kind": "LinkedField",
            "name": "moderationScopes",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "scoped",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Site",
                    "kind": "LinkedField",
                    "name": "sites",
                    "plural": true,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "name",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ],
    "type": "User",
    "abstractKey": null
} as any);
(node as any).hash = 'cdcb6f3777fbecc95bf9302f2f6bba40';
export default node;
