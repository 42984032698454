/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type SiteModeratorActions_viewer = {
    readonly id: string;
    readonly moderationScopes: {
        readonly sites: ReadonlyArray<{
            readonly id: string;
            readonly name: string;
        }> | null;
    } | null;
    readonly " $refType": "SiteModeratorActions_viewer";
};
export type SiteModeratorActions_viewer$data = SiteModeratorActions_viewer;
export type SiteModeratorActions_viewer$key = {
    readonly " $data"?: SiteModeratorActions_viewer$data;
    readonly " $fragmentRefs": FragmentRefs<"SiteModeratorActions_viewer">;
};



const node: ReaderFragment = (function () {
    var v0 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any);
    return {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "metadata": null,
        "name": "SiteModeratorActions_viewer",
        "selections": [
            (v0 /*: any*/),
            {
                "alias": null,
                "args": null,
                "concreteType": "UserModerationScopes",
                "kind": "LinkedField",
                "name": "moderationScopes",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "Site",
                        "kind": "LinkedField",
                        "name": "sites",
                        "plural": true,
                        "selections": [
                            (v0 /*: any*/),
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "name",
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    }
                ],
                "storageKey": null
            }
        ],
        "type": "User",
        "abstractKey": null
    } as any;
})();
(node as any).hash = 'd3817c81e7050708001c1f0bf417969b';
export default node;
