/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type COMMENT_FLAG_REASON = "COMMENT_DETECTED_BANNED_WORD" | "COMMENT_DETECTED_LINKS" | "COMMENT_DETECTED_NEW_COMMENTER" | "COMMENT_DETECTED_PREMOD_USER" | "COMMENT_DETECTED_RECENT_HISTORY" | "COMMENT_DETECTED_REPEAT_POST" | "COMMENT_DETECTED_SPAM" | "COMMENT_DETECTED_SUSPECT_WORD" | "COMMENT_DETECTED_TOXIC" | "COMMENT_REPORTED_ABUSIVE" | "COMMENT_REPORTED_BIO" | "COMMENT_REPORTED_OFFENSIVE" | "COMMENT_REPORTED_OTHER" | "COMMENT_REPORTED_SPAM" | "%future added value";
export type FlagDetailsContainer_comment = {
    readonly flags: {
        readonly nodes: ReadonlyArray<{
            readonly flagger: {
                readonly username: string | null;
                readonly id: string;
            } | null;
            readonly reason: COMMENT_FLAG_REASON | null;
            readonly additionalDetails: string | null;
        }>;
    };
    readonly revision: {
        readonly metadata: {
            readonly perspective: {
                readonly score: number;
            } | null;
        };
    } | null;
    readonly " $refType": "FlagDetailsContainer_comment";
};
export type FlagDetailsContainer_comment$data = FlagDetailsContainer_comment;
export type FlagDetailsContainer_comment$key = {
    readonly " $data"?: FlagDetailsContainer_comment$data;
    readonly " $fragmentRefs": FragmentRefs<"FlagDetailsContainer_comment">;
};



const node: ReaderFragment = ({
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "FlagDetailsContainer_comment",
    "selections": [
        {
            "alias": null,
            "args": null,
            "concreteType": "FlagsConnection",
            "kind": "LinkedField",
            "name": "flags",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Flag",
                    "kind": "LinkedField",
                    "name": "nodes",
                    "plural": true,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "User",
                            "kind": "LinkedField",
                            "name": "flagger",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "username",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "id",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "reason",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "additionalDetails",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "concreteType": "CommentRevision",
            "kind": "LinkedField",
            "name": "revision",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "CommentRevisionMetadata",
                    "kind": "LinkedField",
                    "name": "metadata",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "CommentRevisionPerspectiveMetadata",
                            "kind": "LinkedField",
                            "name": "perspective",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "score",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ],
    "type": "Comment",
    "abstractKey": null
} as any);
(node as any).hash = '8587b782ed2030a6bb1035bddc084fd7';
export default node;
