/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type SiteSelectorContainer_query = {
    readonly sites: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly " $fragmentRefs": FragmentRefs<"SiteSelectorSite_site">;
            };
        }>;
    };
    readonly " $refType": "SiteSelectorContainer_query";
};
export type SiteSelectorContainer_query$data = SiteSelectorContainer_query;
export type SiteSelectorContainer_query$key = {
    readonly " $data"?: SiteSelectorContainer_query$data;
    readonly " $fragmentRefs": FragmentRefs<"SiteSelectorContainer_query">;
};



const node: ReaderFragment = ({
    "argumentDefinitions": [
        {
            "defaultValue": 10,
            "kind": "LocalArgument",
            "name": "count"
        },
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "cursor"
        }
    ],
    "kind": "Fragment",
    "metadata": {
        "connection": [
            {
                "count": "count",
                "cursor": "cursor",
                "direction": "forward",
                "path": [
                    "sites"
                ]
            }
        ]
    },
    "name": "SiteSelectorContainer_query",
    "selections": [
        {
            "alias": "sites",
            "args": null,
            "concreteType": "SitesConnection",
            "kind": "LinkedField",
            "name": "__SitesConfig_sites_connection",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "SiteEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Site",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "id",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "__typename",
                                    "storageKey": null
                                },
                                {
                                    "args": null,
                                    "kind": "FragmentSpread",
                                    "name": "SiteSelectorSite_site"
                                }
                            ],
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "cursor",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "endCursor",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "hasNextPage",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ],
    "type": "Query",
    "abstractKey": null
} as any);
(node as any).hash = 'af4ca6125c97f84b4a271c9170844e4d';
export default node;
