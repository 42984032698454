/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type STORY_MODE = "COMMENTS" | "QA" | "RATINGS_AND_REVIEWS" | "%future added value";
export type STORY_STATUS = "CLOSED" | "OPEN" | "%future added value";
export type ArchiveStoryActionsContainer_story = {
    readonly id: string;
    readonly isArchiving: boolean;
    readonly isArchived: boolean;
    readonly isClosed: boolean;
    readonly status: STORY_STATUS;
    readonly settings: {
        readonly mode: STORY_MODE;
    };
    readonly " $refType": "ArchiveStoryActionsContainer_story";
};
export type ArchiveStoryActionsContainer_story$data = ArchiveStoryActionsContainer_story;
export type ArchiveStoryActionsContainer_story$key = {
    readonly " $data"?: ArchiveStoryActionsContainer_story$data;
    readonly " $fragmentRefs": FragmentRefs<"ArchiveStoryActionsContainer_story">;
};



const node: ReaderFragment = ({
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ArchiveStoryActionsContainer_story",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isArchiving",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isArchived",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isClosed",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "concreteType": "StorySettings",
            "kind": "LinkedField",
            "name": "settings",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "mode",
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ],
    "type": "Story",
    "abstractKey": null
} as any);
(node as any).hash = 'a293f5f6519a825b9d660991e738084e';
export default node;
