/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ModerateCardContainer_settings = {
    readonly locale: string;
    readonly wordList: {
        readonly banned: ReadonlyArray<string>;
        readonly suspect: ReadonlyArray<string>;
    };
    readonly multisite: boolean;
    readonly " $fragmentRefs": FragmentRefs<"MarkersContainer_settings">;
    readonly " $refType": "ModerateCardContainer_settings";
};
export type ModerateCardContainer_settings$data = ModerateCardContainer_settings;
export type ModerateCardContainer_settings$key = {
    readonly " $data"?: ModerateCardContainer_settings$data;
    readonly " $fragmentRefs": FragmentRefs<"ModerateCardContainer_settings">;
};



const node: ReaderFragment = ({
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ModerateCardContainer_settings",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "locale",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "concreteType": "WordList",
            "kind": "LinkedField",
            "name": "wordList",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "banned",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "suspect",
                    "storageKey": null
                }
            ],
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "multisite",
            "storageKey": null
        },
        {
            "args": null,
            "kind": "FragmentSpread",
            "name": "MarkersContainer_settings"
        }
    ],
    "type": "Settings",
    "abstractKey": null
} as any);
(node as any).hash = 'db50563090b03c7189b3acc3ef89f4fd';
export default node;
