/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type COMMENT_FLAG_REASON = "COMMENT_DETECTED_BANNED_WORD" | "COMMENT_DETECTED_LINKS" | "COMMENT_DETECTED_NEW_COMMENTER" | "COMMENT_DETECTED_PREMOD_USER" | "COMMENT_DETECTED_RECENT_HISTORY" | "COMMENT_DETECTED_REPEAT_POST" | "COMMENT_DETECTED_SPAM" | "COMMENT_DETECTED_SUSPECT_WORD" | "COMMENT_DETECTED_TOXIC" | "COMMENT_REPORTED_ABUSIVE" | "COMMENT_REPORTED_BIO" | "COMMENT_REPORTED_OFFENSIVE" | "COMMENT_REPORTED_OTHER" | "COMMENT_REPORTED_SPAM" | "%future added value";
export type COMMENT_STATUS = "APPROVED" | "NONE" | "PREMOD" | "REJECTED" | "SYSTEM_WITHHELD" | "%future added value";
export type TAG = "ADMIN" | "EXPERT" | "FEATURED" | "MODERATOR" | "QUESTION" | "REVIEW" | "STAFF" | "UNANSWERED" | "%future added value";
export type AutomatedActionsContainer_comment = {
    readonly revision: {
        readonly metadata: {
            readonly perspective: {
                readonly score: number;
            } | null;
            readonly externalModeration: ReadonlyArray<{
                readonly name: string;
                readonly analyzedAt: string;
                readonly result: {
                    readonly status: COMMENT_STATUS | null;
                    readonly tags: ReadonlyArray<TAG> | null;
                    readonly actions: ReadonlyArray<{
                        readonly reason: COMMENT_FLAG_REASON | null;
                    }> | null;
                };
            }> | null;
        };
    } | null;
    readonly " $refType": "AutomatedActionsContainer_comment";
};
export type AutomatedActionsContainer_comment$data = AutomatedActionsContainer_comment;
export type AutomatedActionsContainer_comment$key = {
    readonly " $data"?: AutomatedActionsContainer_comment$data;
    readonly " $fragmentRefs": FragmentRefs<"AutomatedActionsContainer_comment">;
};



const node: ReaderFragment = ({
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "AutomatedActionsContainer_comment",
    "selections": [
        {
            "alias": null,
            "args": null,
            "concreteType": "CommentRevision",
            "kind": "LinkedField",
            "name": "revision",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "CommentRevisionMetadata",
                    "kind": "LinkedField",
                    "name": "metadata",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "CommentRevisionPerspectiveMetadata",
                            "kind": "LinkedField",
                            "name": "perspective",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "score",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "CommentRevisionExternalModerationPhaseMetadata",
                            "kind": "LinkedField",
                            "name": "externalModeration",
                            "plural": true,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "name",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "analyzedAt",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "ExternalModerationPhaseResult",
                                    "kind": "LinkedField",
                                    "name": "result",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "status",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "tags",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "ExternalModerationPhaseAction",
                                            "kind": "LinkedField",
                                            "name": "actions",
                                            "plural": true,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "reason",
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ],
    "type": "Comment",
    "abstractKey": null
} as any);
(node as any).hash = 'e29173df13df5e72aee043640deecca6';
export default node;
