/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type COMMENT_FLAG_REASON = "COMMENT_DETECTED_BANNED_WORD" | "COMMENT_DETECTED_LINKS" | "COMMENT_DETECTED_NEW_COMMENTER" | "COMMENT_DETECTED_PREMOD_USER" | "COMMENT_DETECTED_RECENT_HISTORY" | "COMMENT_DETECTED_REPEAT_POST" | "COMMENT_DETECTED_SPAM" | "COMMENT_DETECTED_SUSPECT_WORD" | "COMMENT_DETECTED_TOXIC" | "COMMENT_REPORTED_ABUSIVE" | "COMMENT_REPORTED_BIO" | "COMMENT_REPORTED_OFFENSIVE" | "COMMENT_REPORTED_OTHER" | "COMMENT_REPORTED_SPAM" | "%future added value";
export type COMMENT_STATUS = "APPROVED" | "NONE" | "PREMOD" | "REJECTED" | "SYSTEM_WITHHELD" | "%future added value";
export type TAG = "ADMIN" | "EXPERT" | "FEATURED" | "MODERATOR" | "QUESTION" | "REVIEW" | "STAFF" | "UNANSWERED" | "%future added value";
export type ModerateCardDetailsContainer_comment = {
    readonly id: string;
    readonly status: COMMENT_STATUS;
    readonly tags: ReadonlyArray<{
        readonly code: TAG;
    }>;
    readonly editing: {
        readonly edited: boolean;
    };
    readonly revision: {
        readonly actionCounts: {
            readonly flag: {
                readonly reasons: {
                    readonly COMMENT_REPORTED_OFFENSIVE: number;
                    readonly COMMENT_REPORTED_ABUSIVE: number;
                    readonly COMMENT_REPORTED_SPAM: number;
                    readonly COMMENT_REPORTED_OTHER: number;
                    readonly COMMENT_DETECTED_TOXIC: number;
                    readonly COMMENT_DETECTED_SPAM: number;
                };
            };
            readonly reaction: {
                readonly total: number;
            };
        };
        readonly metadata: {
            readonly perspective: {
                readonly score: number;
            } | null;
            readonly externalModeration: ReadonlyArray<{
                readonly name: string;
                readonly result: {
                    readonly status: COMMENT_STATUS | null;
                    readonly tags: ReadonlyArray<TAG> | null;
                    readonly actions: ReadonlyArray<{
                        readonly reason: COMMENT_FLAG_REASON | null;
                    }> | null;
                };
            }> | null;
        };
    } | null;
    readonly " $fragmentRefs": FragmentRefs<"FlagDetailsContainer_comment" | "CommentRevisionContainer_comment" | "LinkDetailsContainer_comment" | "AutomatedActionsContainer_comment">;
    readonly " $refType": "ModerateCardDetailsContainer_comment";
};
export type ModerateCardDetailsContainer_comment$data = ModerateCardDetailsContainer_comment;
export type ModerateCardDetailsContainer_comment$key = {
    readonly " $data"?: ModerateCardDetailsContainer_comment$data;
    readonly " $fragmentRefs": FragmentRefs<"ModerateCardDetailsContainer_comment">;
};



const node: ReaderFragment = (function () {
    var v0 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "status",
        "storageKey": null
    } as any);
    return {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "metadata": null,
        "name": "ModerateCardDetailsContainer_comment",
        "selections": [
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
            },
            (v0 /*: any*/),
            {
                "alias": null,
                "args": null,
                "concreteType": "Tag",
                "kind": "LinkedField",
                "name": "tags",
                "plural": true,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "code",
                        "storageKey": null
                    }
                ],
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "concreteType": "EditInfo",
                "kind": "LinkedField",
                "name": "editing",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "edited",
                        "storageKey": null
                    }
                ],
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "concreteType": "CommentRevision",
                "kind": "LinkedField",
                "name": "revision",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "ActionCounts",
                        "kind": "LinkedField",
                        "name": "actionCounts",
                        "plural": false,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": "FlagActionCounts",
                                "kind": "LinkedField",
                                "name": "flag",
                                "plural": false,
                                "selections": [
                                    {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "FlagReasonActionCounts",
                                        "kind": "LinkedField",
                                        "name": "reasons",
                                        "plural": false,
                                        "selections": [
                                            {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "COMMENT_REPORTED_OFFENSIVE",
                                                "storageKey": null
                                            },
                                            {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "COMMENT_REPORTED_ABUSIVE",
                                                "storageKey": null
                                            },
                                            {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "COMMENT_REPORTED_SPAM",
                                                "storageKey": null
                                            },
                                            {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "COMMENT_REPORTED_OTHER",
                                                "storageKey": null
                                            },
                                            {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "COMMENT_DETECTED_TOXIC",
                                                "storageKey": null
                                            },
                                            {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "COMMENT_DETECTED_SPAM",
                                                "storageKey": null
                                            }
                                        ],
                                        "storageKey": null
                                    }
                                ],
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": "ReactionActionCounts",
                                "kind": "LinkedField",
                                "name": "reaction",
                                "plural": false,
                                "selections": [
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "total",
                                        "storageKey": null
                                    }
                                ],
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "CommentRevisionMetadata",
                        "kind": "LinkedField",
                        "name": "metadata",
                        "plural": false,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": "CommentRevisionPerspectiveMetadata",
                                "kind": "LinkedField",
                                "name": "perspective",
                                "plural": false,
                                "selections": [
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "score",
                                        "storageKey": null
                                    }
                                ],
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": "CommentRevisionExternalModerationPhaseMetadata",
                                "kind": "LinkedField",
                                "name": "externalModeration",
                                "plural": true,
                                "selections": [
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "name",
                                        "storageKey": null
                                    },
                                    {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "ExternalModerationPhaseResult",
                                        "kind": "LinkedField",
                                        "name": "result",
                                        "plural": false,
                                        "selections": [
                                            (v0 /*: any*/),
                                            {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "tags",
                                                "storageKey": null
                                            },
                                            {
                                                "alias": null,
                                                "args": null,
                                                "concreteType": "ExternalModerationPhaseAction",
                                                "kind": "LinkedField",
                                                "name": "actions",
                                                "plural": true,
                                                "selections": [
                                                    {
                                                        "alias": null,
                                                        "args": null,
                                                        "kind": "ScalarField",
                                                        "name": "reason",
                                                        "storageKey": null
                                                    }
                                                ],
                                                "storageKey": null
                                            }
                                        ],
                                        "storageKey": null
                                    }
                                ],
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    }
                ],
                "storageKey": null
            },
            {
                "args": null,
                "kind": "FragmentSpread",
                "name": "FlagDetailsContainer_comment"
            },
            {
                "args": null,
                "kind": "FragmentSpread",
                "name": "CommentRevisionContainer_comment"
            },
            {
                "args": null,
                "kind": "FragmentSpread",
                "name": "LinkDetailsContainer_comment"
            },
            {
                "args": null,
                "kind": "FragmentSpread",
                "name": "AutomatedActionsContainer_comment"
            }
        ],
        "type": "Comment",
        "abstractKey": null
    } as any;
})();
(node as any).hash = '01b471b8d7234e2d37b8136a2cde4a4f';
export default node;
