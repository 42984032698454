/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type NewCommentersConfigContainer_settings = {
    readonly newCommenters: {
        readonly premodEnabled: boolean;
        readonly approvedCommentsThreshold: number;
    };
    readonly " $refType": "NewCommentersConfigContainer_settings";
};
export type NewCommentersConfigContainer_settings$data = NewCommentersConfigContainer_settings;
export type NewCommentersConfigContainer_settings$key = {
    readonly " $data"?: NewCommentersConfigContainer_settings$data;
    readonly " $fragmentRefs": FragmentRefs<"NewCommentersConfigContainer_settings">;
};



const node: ReaderFragment = ({
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "NewCommentersConfigContainer_settings",
    "selections": [
        {
            "alias": null,
            "args": null,
            "concreteType": "NewCommentersConfiguration",
            "kind": "LinkedField",
            "name": "newCommenters",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "premodEnabled",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "approvedCommentsThreshold",
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ],
    "type": "Settings",
    "abstractKey": null
} as any);
(node as any).hash = '6b9a56f04ddf786dd3b693f8ef064436';
export default node;
