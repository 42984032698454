/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type MODERATION_MODE = "POST" | "PRE" | "SPECIFIC_SITES_PRE" | "%future added value";
export type PreModerateAllCommentsConfig_formValues = {
    readonly moderation: MODERATION_MODE | null;
    readonly premoderateAllCommentsSites: ReadonlyArray<string>;
    readonly " $refType": "PreModerateAllCommentsConfig_formValues";
};
export type PreModerateAllCommentsConfig_formValues$data = PreModerateAllCommentsConfig_formValues;
export type PreModerateAllCommentsConfig_formValues$key = {
    readonly " $data"?: PreModerateAllCommentsConfig_formValues$data;
    readonly " $fragmentRefs": FragmentRefs<"PreModerateAllCommentsConfig_formValues">;
};



const node: ReaderFragment = ({
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "PreModerateAllCommentsConfig_formValues",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "moderation",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "premoderateAllCommentsSites",
            "storageKey": null
        }
    ],
    "type": "Settings",
    "abstractKey": null
} as any);
(node as any).hash = '785c3fc6dd4f4351cbe74c6ec8865376';
export default node;
