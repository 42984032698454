/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type StaffConfig_formValues = {
    readonly staff: {
        readonly staffLabel: string;
        readonly adminLabel: string;
        readonly moderatorLabel: string;
    };
    readonly " $refType": "StaffConfig_formValues";
};
export type StaffConfig_formValues$data = StaffConfig_formValues;
export type StaffConfig_formValues$key = {
    readonly " $data"?: StaffConfig_formValues$data;
    readonly " $fragmentRefs": FragmentRefs<"StaffConfig_formValues">;
};



const node: ReaderFragment = ({
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "StaffConfig_formValues",
    "selections": [
        {
            "alias": null,
            "args": null,
            "concreteType": "StaffConfiguration",
            "kind": "LinkedField",
            "name": "staff",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "staffLabel",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "adminLabel",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "moderatorLabel",
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ],
    "type": "Settings",
    "abstractKey": null
} as any);
(node as any).hash = 'd0ddddcfd32512a34829ff1968347bfe';
export default node;
