/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type StoryInfoDrawerContainer_settings = {
    readonly " $fragmentRefs": FragmentRefs<"ModerateStoryButton_settings">;
    readonly " $refType": "StoryInfoDrawerContainer_settings";
};
export type StoryInfoDrawerContainer_settings$data = StoryInfoDrawerContainer_settings;
export type StoryInfoDrawerContainer_settings$key = {
    readonly " $data"?: StoryInfoDrawerContainer_settings$data;
    readonly " $fragmentRefs": FragmentRefs<"StoryInfoDrawerContainer_settings">;
};



const node: ReaderFragment = ({
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "StoryInfoDrawerContainer_settings",
    "selections": [
        {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ModerateStoryButton_settings"
        }
    ],
    "type": "Settings",
    "abstractKey": null
} as any);
(node as any).hash = 'b1ea27f9ce7bb033dceb5af782bf6104';
export default node;
