/* tslint:disable */
/* eslint-disable */
/* @relayHash 2d3f9db2755cc4649e11fbf3ad1ab457 */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type UserHistoryDrawerQueryVariables = {
    userID: string;
};
export type UserHistoryDrawerQueryResponse = {
    readonly user: {
        readonly " $fragmentRefs": FragmentRefs<"UserHistoryDrawerContainer_user">;
    } | null;
    readonly settings: {
        readonly " $fragmentRefs": FragmentRefs<"UserHistoryDrawerContainer_settings">;
    };
    readonly viewer: {
        readonly " $fragmentRefs": FragmentRefs<"UserHistoryDrawerContainer_viewer">;
    } | null;
};
export type UserHistoryDrawerQuery = {
    readonly response: UserHistoryDrawerQueryResponse;
    readonly variables: UserHistoryDrawerQueryVariables;
};



/*
query UserHistoryDrawerQuery(
  $userID: ID!
) {
  user(id: $userID) {
    ...UserHistoryDrawerContainer_user
    id
  }
  settings {
    ...UserHistoryDrawerContainer_settings
    id
  }
  viewer {
    ...UserHistoryDrawerContainer_viewer
    id
  }
}

fragment MemberBioContainer_user on User {
  bio
}

fragment RecentHistoryContainer_settings on Settings {
  recentCommentHistory {
    enabled
    timeFrame
    triggerRejectionRate
  }
}

fragment RecentHistoryContainer_user on User {
  recentCommentHistory {
    statuses {
      NONE
      APPROVED
      REJECTED
      PREMOD
      SYSTEM_WITHHELD
    }
  }
}

fragment UserBadgesContainer_user on User {
  badges
}

fragment UserHistoryDrawerContainer_settings on Settings {
  ...RecentHistoryContainer_settings
  ...UserStatusChangeContainer_settings
  organization {
    name
  }
}

fragment UserHistoryDrawerContainer_user on User {
  ...UserBadgesContainer_user
  ...UserStatusChangeContainer_user
  ...UserStatusDetailsContainer_user
  ...RecentHistoryContainer_user
  ...MemberBioContainer_user
  moderatorNotes {
    id
  }
  id
  username
  email
  createdAt
}

fragment UserHistoryDrawerContainer_viewer on User {
  ...UserStatusChangeContainer_viewer
}

fragment UserStatusChangeContainer_settings on Settings {
  organization {
    name
  }
  multisite
}

fragment UserStatusChangeContainer_user on User {
  id
  role
  username
  status {
    ban {
      active
      sites {
        id
        name
      }
    }
    suspension {
      active
    }
    premod {
      active
    }
    warning {
      active
    }
  }
  ...UserStatusContainer_user
}

fragment UserStatusChangeContainer_viewer on User {
  role
  moderationScopes {
    scoped
    sites {
      id
      name
    }
  }
}

fragment UserStatusContainer_user on User {
  status {
    current
    ban {
      sites {
        id
      }
    }
  }
}

fragment UserStatusDetailsContainer_user on User {
  status {
    warning {
      active
      history {
        active
        createdBy {
          username
          id
        }
        createdAt
      }
    }
    ban {
      active
      history {
        active
        createdAt
        createdBy {
          username
          id
        }
      }
    }
    suspension {
      until
      active
      history {
        active
        from {
          start
          finish
        }
        createdBy {
          username
          id
        }
      }
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "userID"
        } as any)
    ], v1 = [
        ({
            "kind": "Variable",
            "name": "id",
            "variableName": "userID"
        } as any)
    ], v2 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any), v3 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "role",
        "storageKey": null
    } as any), v4 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "username",
        "storageKey": null
    } as any), v5 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "active",
        "storageKey": null
    } as any), v6 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
    } as any), v7 = ({
        "alias": null,
        "args": null,
        "concreteType": "Site",
        "kind": "LinkedField",
        "name": "sites",
        "plural": true,
        "selections": [
            (v2 /*: any*/),
            (v6 /*: any*/)
        ],
        "storageKey": null
    } as any), v8 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "createdAt",
        "storageKey": null
    } as any), v9 = ({
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "createdBy",
        "plural": false,
        "selections": [
            (v4 /*: any*/),
            (v2 /*: any*/)
        ],
        "storageKey": null
    } as any);
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "UserHistoryDrawerQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                        {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "UserHistoryDrawerContainer_user"
                        }
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Settings",
                    "kind": "LinkedField",
                    "name": "settings",
                    "plural": false,
                    "selections": [
                        {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "UserHistoryDrawerContainer_settings"
                        }
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "viewer",
                    "plural": false,
                    "selections": [
                        {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "UserHistoryDrawerContainer_viewer"
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "UserHistoryDrawerQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "badges",
                            "storageKey": null
                        },
                        (v2 /*: any*/),
                        (v3 /*: any*/),
                        (v4 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "UserStatus",
                            "kind": "LinkedField",
                            "name": "status",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "BanStatus",
                                    "kind": "LinkedField",
                                    "name": "ban",
                                    "plural": false,
                                    "selections": [
                                        (v5 /*: any*/),
                                        (v7 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "BanStatusHistory",
                                            "kind": "LinkedField",
                                            "name": "history",
                                            "plural": true,
                                            "selections": [
                                                (v5 /*: any*/),
                                                (v8 /*: any*/),
                                                (v9 /*: any*/)
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "SuspensionStatus",
                                    "kind": "LinkedField",
                                    "name": "suspension",
                                    "plural": false,
                                    "selections": [
                                        (v5 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "until",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "SuspensionStatusHistory",
                                            "kind": "LinkedField",
                                            "name": "history",
                                            "plural": true,
                                            "selections": [
                                                (v5 /*: any*/),
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "TimeRange",
                                                    "kind": "LinkedField",
                                                    "name": "from",
                                                    "plural": false,
                                                    "selections": [
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "start",
                                                            "storageKey": null
                                                        },
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "finish",
                                                            "storageKey": null
                                                        }
                                                    ],
                                                    "storageKey": null
                                                },
                                                (v9 /*: any*/)
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "PremodStatus",
                                    "kind": "LinkedField",
                                    "name": "premod",
                                    "plural": false,
                                    "selections": [
                                        (v5 /*: any*/)
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "WarningStatus",
                                    "kind": "LinkedField",
                                    "name": "warning",
                                    "plural": false,
                                    "selections": [
                                        (v5 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "WarningStatusHistory",
                                            "kind": "LinkedField",
                                            "name": "history",
                                            "plural": true,
                                            "selections": [
                                                (v5 /*: any*/),
                                                (v9 /*: any*/),
                                                (v8 /*: any*/)
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "current",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "RecentCommentHistory",
                            "kind": "LinkedField",
                            "name": "recentCommentHistory",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "CommentStatusCounts",
                                    "kind": "LinkedField",
                                    "name": "statuses",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "NONE",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "APPROVED",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "REJECTED",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "PREMOD",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "SYSTEM_WITHHELD",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "bio",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "ModeratorNote",
                            "kind": "LinkedField",
                            "name": "moderatorNotes",
                            "plural": true,
                            "selections": [
                                (v2 /*: any*/)
                            ],
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "email",
                            "storageKey": null
                        },
                        (v8 /*: any*/)
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Settings",
                    "kind": "LinkedField",
                    "name": "settings",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "RecentCommentHistoryConfiguration",
                            "kind": "LinkedField",
                            "name": "recentCommentHistory",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "enabled",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "timeFrame",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "triggerRejectionRate",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Organization",
                            "kind": "LinkedField",
                            "name": "organization",
                            "plural": false,
                            "selections": [
                                (v6 /*: any*/)
                            ],
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "multisite",
                            "storageKey": null
                        },
                        (v2 /*: any*/)
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "viewer",
                    "plural": false,
                    "selections": [
                        (v3 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "UserModerationScopes",
                            "kind": "LinkedField",
                            "name": "moderationScopes",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "scoped",
                                    "storageKey": null
                                },
                                (v7 /*: any*/)
                            ],
                            "storageKey": null
                        },
                        (v2 /*: any*/)
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "id": "2d3f9db2755cc4649e11fbf3ad1ab457",
            "metadata": {},
            "name": "UserHistoryDrawerQuery",
            "operationKind": "query",
            "text": null
        }
    } as any;
})();
(node as any).hash = 'da2fc037a7fbea84913bc5e678019716';
export default node;
