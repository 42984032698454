/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type USER_ROLE = "ADMIN" | "COMMENTER" | "MODERATOR" | "STAFF" | "%future added value";
export type ErrorReporterSetUserContainer_viewer = {
    readonly id: string;
    readonly username: string | null;
    readonly role: USER_ROLE;
    readonly " $refType": "ErrorReporterSetUserContainer_viewer";
};
export type ErrorReporterSetUserContainer_viewer$data = ErrorReporterSetUserContainer_viewer;
export type ErrorReporterSetUserContainer_viewer$key = {
    readonly " $data"?: ErrorReporterSetUserContainer_viewer$data;
    readonly " $fragmentRefs": FragmentRefs<"ErrorReporterSetUserContainer_viewer">;
};



const node: ReaderFragment = ({
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ErrorReporterSetUserContainer_viewer",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "username",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "role",
            "storageKey": null
        }
    ],
    "type": "User",
    "abstractKey": null
} as any);
(node as any).hash = '91b6d481b23ea8d2b584cf298907c9f3';
export default node;
